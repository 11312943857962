import React, { useState } from "react";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import { APP_COPYRIGHT } from "../utils/AppConstants";
import AlertComponent from "./AlertComponent";
import { AlertDataProps } from "../types/Common";

const Footer = ({ scrollToAbout }: any) => {
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const navigate = useNavigate();
  const handleLoginClick = () => {
    setAlertData({
      message: "This feature is coming soon!!",
      type: "info",
    });
    //navigate("/auth/login");
  };

  return (
    <Box sx={{ backgroundColor: "#000", color: "#fff", pt: "8%", pb: "2%" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" sx={{ flexGrow: 1, marginBottom: "1em" }}>
              <img
                src="./static/svg/logo.svg"
                alt="AEG Logo"
                style={{ width: "60px" }}
              />
              <Typography variant="h4" sx={{ marginLeft: 1 }}>
                AEG
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                width: { xs: "100%", sm: "80%" },
                fontFamily: "system-ui",
                fontWeight: "100",
                fontSize: "16px",
              }}
            >
              Introducing AEG (Arab Emirates Gold), a gold-pegged digital asset that revolutionizes the way you own and invest in gold.
              AEG is exclusively acquired through AED (Arab Emirates Dirham) fiat currency, ensuring a secure and transparent transaction process.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              QUICK LINKS
            </Typography>
            <Link
              onClick={scrollToAbout}
              color="inherit"
              variant="body2"
              sx={{
                cursor: "pointer",
                display: "block",
                mt: 3,
                fontWeight: "100",
                textDecoration: "none",
                fontFamily: "system-ui",
                fontSize: "15px",
              }}
            >
              ABOUT
            </Link>
            <Link
              onClick={handleLoginClick}
              color="inherit"
              variant="body2"
              sx={{
                cursor: "pointer",
                display: "block",
                mt: 1,
                fontWeight: "100",
                textDecoration: "none",
                fontFamily: "system-ui",
                fontSize: "15px",
              }}
            >
              BUY AEG
            </Link>
            <Link
              onClick={handleLoginClick}
              color="inherit"
              variant="body2"
              sx={{
                cursor: "pointer",
                display: "block",
                mt: 1,
                fontWeight: "100",
                textDecoration: "none",
                fontFamily: "system-ui",
                fontSize: "15px",
              }}
            >
              SELL AEG
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textDecoration: "none" }}
            >
              CUSTOMER SUPPORT
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 3,
                fontWeight: "100",
                textDecoration: "none",
              }}
            >
              <EmailIcon sx={{ mr: 1, color: "#F1B20F" }} />
              <Link
                href="mailto:info@falconaeg.ae"
                color="inherit"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  fontFamily: "system-ui",
                  fontWeight: "100",
                  fontSize: "15px",
                }}
              >
                info@aegt.io
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 1,
                fontWeight: "100",
                textDecoration: "none",
              }}
            >
              <PhoneIcon sx={{ mr: 1, color: "#F1B20F" }} />
              <Link
                href="tel:+15307468609"
                color="inherit"
                variant="body2"
                sx={{
                  textDecoration: "none",
                  fontFamily: "system-ui",
                  fontWeight: "100",
                  fontSize: "15px",
                }}
              >
                +97 (154)-276-7676
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            mt: 4,
            borderTop: "1px solid #444",
            pt: 2,
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "100",
          }}
        >
          <Grid item>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "system-ui",
                fontWeight: "100",
                fontSize: "15px",
              }}
            >
              {APP_COPYRIGHT}
            </Typography>
          </Grid>
          <Grid item>
            <Link
              href="/privacy-policy"
              color="inherit"
              variant="body2"
              sx={{
                display: "block",
                mt: 1,
                textDecoration: "none",
                fontFamily: "system-ui",
                fontWeight: "100",
                fontSize: "15px",
              }}
            >
              Privacy & Policy
            </Link>
          </Grid>
        </Grid>
      </Container>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </Box>
  );
};

export default Footer;
