import { takeLatest, put, call } from "redux-saga/effects";

import axiosInstance from "../../api/config";
import {
  dashboardRequest,
  dashboardFailure,
  dashboardSuccess,
} from "../reducers/DashboardReducer";

function* requestDashboardSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/dashboard");
    if (response.status === 200) {
      yield put(dashboardSuccess({ result: response.data }));
    } else {
      yield put(dashboardFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      dashboardFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchDashboardSaga() {
  yield takeLatest(dashboardRequest.type, requestDashboardSaga);
}

export default watchFetchDashboardSaga;
