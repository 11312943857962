import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  userProfileFailure,
  userProfileRequest,
  userProfileSuccess,
} from "../reducers/ProfileReducer";

function* requestProfileSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/profile");
    yield put(userProfileSuccess(response.data.data.personal_details));
  } catch (error: any) {
    yield put(
      userProfileFailure(
        error?.response ? error?.response?.data : error?.message
      )
    );
  }
}

export function* watchFetchProfileSaga() {
  yield takeLatest(userProfileRequest.type, requestProfileSaga);
}
