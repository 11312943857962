import { takeLatest, put, call } from "redux-saga/effects";
import {
  aegDetailFailure,
  aegDetailRequest,
  aegDetailSuccess,
} from "../reducers/AEGDetailsReducer";
import axiosInstance from "../../api/config";
import { AEGDetailResponse } from "../../types/AEGDetailsModel";

function* requestAEGDetailsSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/aeg-details");
    if (response.status === 200) {
      const data: AEGDetailResponse = response.data;
      yield put(aegDetailSuccess({ result: data.data }));
    } else {
      yield put(aegDetailFailure(response.data.message));
    }
  } catch (error: any) {
    yield put(
      aegDetailFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchAEGDetailsSaga() {
  yield takeLatest(aegDetailRequest.type, requestAEGDetailsSaga);
}

export default watchFetchAEGDetailsSaga;
