import axios from "axios";
import { STORE_KEY } from "../utils/AppConstants";

const apiURL = process.env.REACT_APP_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: apiURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["x-api-key"] = apiKey;
    config.headers["x-source"] = "user-web";
    const sessionData = localStorage.getItem(STORE_KEY.SESSION_DATA);
    if (sessionData) {
      const data = JSON.parse(sessionData);
      config.headers.Authorization = `Bearer ${data.token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      console.log("Response data:", error.response.data);
      console.log("Response status:", error.response.status);
    } else if (error.request) {
      console.log("Request:", error.request);
    } else {
      console.log("Error:", error.message);
    }
    console.log(
      "interceptor 🚫",
      `${error?.config?.baseURL}${error?.config?.url}\nSTATUS CODE: ${
        error.response.status
      }\nDATA: ${JSON.stringify(error.response.data)}`
    );
    return error?.response ? error.response : error;
  }
);

export const setAuthToken = (token: string) => {
  if (token) {
    console.log("Set login token ", token);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export default axiosInstance;
