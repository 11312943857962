export const Table = (theme: any) => {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "0.5 solid rgba(255, 255, 255, 0.2)",
        },
      },
    },
  };
};
