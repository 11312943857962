const Logo = () => {
  return (
    <img
      src="./static/svg/logo.svg"
      height={22}
      style={{ marginRight: 7 }}
      alt="Logo"
    />
  );
};
export default Logo;
