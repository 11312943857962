import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./AuthReducer";
import DashboardReducer from "./DashboardReducer";
import ProfileReducer from "./ProfileReducer";
import AEGDetailsReducer from "./AEGDetailsReducer";
import TransactionReducer from "./TransactionReducer";
import WalletReducer from "./WalletReducer";
import bankReducer from "./BankReducer";
import currencyListReducer from "./CurrencyListReducer";

const AppReducer = combineReducers({
  authState: authReducer,
  dashboardState: DashboardReducer,
  userProfileState: ProfileReducer,
  aegDetailState: AEGDetailsReducer,
  walletState: WalletReducer,
  bankState: bankReducer,
  currencyState: currencyListReducer,
  transactionState: TransactionReducer,
});
export type RootState = ReturnType<typeof AppReducer>;
const RootReducer = (state: any, action: any) => {
  if (action.type === "LOGOUT_REQUEST") {
    return AppReducer(undefined, action);
  }
  return AppReducer(state, action);
};
export default RootReducer;
