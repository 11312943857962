export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 92;
export const APP_VERSION= "v0.0.2";
export const APP_COPYRIGHT = "© 2024 AEG FOR INVESTMENT IN TECH ENTERPRISES & MGMT LLC. All Rights Reserved"

export const STORE_KEY = {
  SESSION_DATA: "SESSION_DATA",
  TEMP_USER_DATA: "TEMP_USER_DATA",
  SESSION_TOKEN: "SESSION_TOKEN",
};
