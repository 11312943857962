import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicyPage = () => {

    return (
        <div style={{ backgroundColor: 'black' }}>
            <Navbar />
            <Typography variant="h2" component="div" sx={{ mb: 4, textAlign: 'center',mt:5, size: '52px', padding: '0px 100px 10px 100px', }}>
            Privacy Policy
        </Typography>
            <Container style={{ color: 'white' }}>
                <Box my={4}>
                  
                    <Typography variant="body1" paragraph>
                        Effective Date: 12 June 2024
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ pt: 4 }} >
                        This Privacy Policy describes how AEG FOR INVESTMENT IN TECHNOLOGICAL ENTERPRISES AND MANAGEMENT L.L.C
                        ("we," "us," "our") collects, uses, and discloses information about you when you access or use our digital gold wallet application (the "App") and the services available through the App (the "Services").
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Information We Collect
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We collect information you provide directly to us when you use the App or otherwise interact with us, including when you create an account, make a purchase, or communicate with us. The types of information we may collect include:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - <strong>Personal Information:</strong> Name, email address, mailing address, phone number, and other contact information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - <strong>Financial Information:</strong> Bank account or credit card information.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - <strong>Transaction Information:</strong> Details about transactions you make through the App, such as the amount, date, time, and parties involved.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - <strong>Usage Information:</strong> Information about how you use the App, such as your interactions with the App's features and content.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        How We Use Your Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may use the information we collect for various purposes, including to:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Provide, maintain, and improve the App and Services;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Process transactions and send you related information;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Respond to your comments, questions, and requests;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Communicate with you about products, services, offers, promotions, and events;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Monitor and analyze trends, usage, and activities in connection with the App and Services; and
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - Detect, investigate, and prevent fraudulent transactions and other illegal activities.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Sharing of Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may share your information as follows:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - In response to a request for information if we believe disclosure is in accordance with any applicable law, regulation, or legal process;
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of us or others; and
                    </Typography>
                    <Typography variant="body1" paragraph>
                        - With your consent or at your direction.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Data Retention
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We will retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Security
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We take reasonable measures to help protect your information from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Changes to this Privacy Policy
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting a revised version of this Privacy Policy on our website or by other means as required by law.
                    </Typography>
                    <Typography variant="h5" gutterBottom sx={{ pt: 4 }}>
                        Contact Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you have any questions about this Privacy Policy, please contact us at info@aegt.io.
                    </Typography>
                </Box>
            </Container>
            <Footer />
        </div >
    );
};

export default PrivacyPolicyPage;
