import StarIcon from "@mui/icons-material/Star";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import SecurityIcon from "@mui/icons-material/Security";
import GppGoodIcon from "@mui/icons-material/GppGood";

export const items = [
  {
    icon: <StarIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Gold-Backed Stability",
    description:
      "AEG (Arab Emirates Gold) is a gold-pegged digital asset that provides inherent stability by being directly linked to the value of gold. Each AEG digital gold represents a tangible asset, ensuring its value is supported by the precious metal.",
  },
  {
    icon: <AttachMoneyIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Exclusive AED Fiat Purchase",
    description:
      "AEG digital gold can only be acquired through Arab Emirates Dirham (AED) fiat currency, ensuring a controlled and secure transaction process. This exclusive purchasing method adds an extra layer of trust and accessibility for investors.",
  },
  {
    icon: <SwapHorizIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Seamless Integration",
    description:
      "Our intuitive Decentralized Application (Dapp) allows users to effortlessly purchase AEG digital gold. Connect your wallet, make an AED payment through credit/debit card, and witness the automated transfer of AEG digital gold.",
  },
  {
    icon: <CreditCardIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Transparent Transaction Process",
    description:
      "We prioritize transparency in every step. The payment process is fully traceable, and once the payment hits your bank account, the exact number of AEG digital gold corresponding to the current gold price will be instantly transferred.",
  },
  {
    icon: <SecurityIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Real-Time Gold Price Conversion",
    description:
      "The value of each AEG digital gold is equivalent to the current market price of gold, eliminating the need for guesswork or arbitrary pricing. Stay up-to-date with real-time conversions and make informed investment decisions.",
  },
  {
    icon: <GppGoodIcon sx={{ color: "#F1B20F", fontSize: 30 }} />,
    title: "Secure Digital Gold Ownership",
    description:
      "By leveraging blockchain technology, AEG ensures secure and tamper-proof ownership of digital gold. Benefit from the advantages of digital assets while maintaining the inherent value and stability of physical gold.",
  },
];
