import { takeLatest, put, call } from "redux-saga/effects";

import axiosInstance from "../../api/config";
import { bankRequest, bankFailure, bankSuccess } from "../reducers/BankReducer";
import { BankDetailResponse } from "../../types/BankDetailsModel";

function* requestBankSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/bank-details");
    if (response.status === 200) {
      const data: BankDetailResponse = response.data;
      yield put(bankSuccess(data.data));
    } else {
      yield put(bankFailure(response.message));
    }
  } catch (error: any) {
    yield put(
      bankFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchBankSaga() {
  yield takeLatest(bankRequest.type, requestBankSaga);
}

export default watchFetchBankSaga;
