import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import {
  walletSuccess,
  walletFailure,
  walletRequest,
} from "../reducers/WalletReducer";

function* requestWalletSaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/wallet");
    console.log("Wallet request: ", response.data);

    yield put(walletSuccess(response.data));
  } catch (error: any) {
    console.log("Wallet request error: ", error);
    yield put(
      walletFailure(error.response ? error.response.data : error.message)
    );
  }
}

export function* watchFetchWalletSaga() {
  yield takeLatest(walletRequest.type, requestWalletSaga);
}
