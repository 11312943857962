import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginData } from "../../types/LoginModel";

interface LoginState {
  result: LoginData | null;
  token: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: LoginState = {
  result: null,
  loading: false,
  error: null,
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest(
      state,
      action: PayloadAction<{
        email: string;
        password: string;
        recaptchaToken: string;
      }>
    ) {
      state.loading = true;
      state.error = null;
    },
    loginSuccess(
      state,
      action: PayloadAction<{ token: string; result: LoginData }>
    ) {
      state.loading = false;
      state.result = action.payload.result;
      state.token = action.payload.token;
    },
    loginFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;
