import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransactionModel } from "../../types/TransactionsModel";

interface TransactionState {
  result: TransactionModel | null;
  loading: boolean;
  error: string | null;
}

const initialState: TransactionState = {
  result: null,
  loading: false,
  error: null,
};
const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    transactionRequest(state, action: PayloadAction<any> ) {
      state.loading = true;
      state.error = null;
    },
    transactionSuccess(
      state,
      action: PayloadAction<{ result: TransactionModel }>
    ) {
      state.loading = false;
      state.result = action.payload.result;
    },
    transactionFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { transactionRequest, transactionSuccess, transactionFailure } =
  transactionSlice.actions;

export default transactionSlice.reducer;
