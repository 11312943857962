import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import { LoginResponse } from "../../types/LoginModel";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  loginFailure,
  loginRequest,
  loginSuccess,
} from "../reducers/AuthReducer";

export interface LoginPayload {
  email: string;
  password: string;
  recaptchaToken: string;
}

function* requestLoginSaga(action: PayloadAction<LoginPayload>): any {
  try {
    const response = yield call(axiosInstance.post, "/login", action.payload);
    if (response.status === 200) {
      const data: LoginResponse = response.data;
      yield put(loginSuccess({ token: data.data.token, result: data.data }));
    } else {
      yield put(loginFailure(response.message));
    }
  } catch (error: any) {
    yield put(
      loginFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchAuthSaga() {
  yield takeLatest(loginRequest.type, requestLoginSaga);
}

export default watchFetchAuthSaga;
