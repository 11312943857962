import { takeLatest, put, call } from "redux-saga/effects";
import axiosInstance from "../../api/config";
import { PayloadAction } from "@reduxjs/toolkit";
import { TransactionModelResponse } from "../../types/TransactionsModel";
import {
  transactionFailure,
  transactionRequest,
  transactionSuccess,
} from "../reducers/TransactionReducer";

function* requestTransactionSaga(action: PayloadAction<string>): any {
  try {
    const transactionRequestStr = action.payload
      ? `/transactions?${action.payload}`
      : "/transactions?";
    const response = yield call(axiosInstance.get, transactionRequestStr);
    if (response.status === 200) {
      const data: TransactionModelResponse = response.data;
      yield put(transactionSuccess({ result: data.data }));
    } else {
      yield put(transactionFailure(response.message));
    }
  } catch (error: any) {
    yield put(
      transactionFailure(error.response ? error.response.data : error.message)
    );
  }
}

export function* watchFetchTransactionSaga() {
  yield takeLatest(transactionRequest.type, requestTransactionSaga);
}
