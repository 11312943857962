import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BankDetailData } from "../../types/BankDetailsModel";

interface BankState {
  result: BankDetailData[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: BankState = {
  result: null,
  loading: false,
  error: null,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    bankRequest(state) {
      state.loading = true;
      state.error = null;
    },
    bankSuccess(state, action: PayloadAction<BankDetailData[]>) {
      state.loading = false;
      state.result = action.payload;
    },
    bankFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { bankRequest, bankSuccess, bankFailure } = bankSlice.actions;

export default bankSlice.reducer;
