import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Logo from "./logo";
import { Container, Grid } from "@mui/material";
import AlertComponent from "./AlertComponent";
import { Link, useNavigate } from "react-router-dom";
import { AlertDataProps } from "../types/Common";

const Navbar = ({ scrollToAbout }: any) => {
  const [alertData, setAlertData] = useState<AlertDataProps>({
    message: "",
    type: "error",
  });
  const navigate = useNavigate();

  const handleLoginClick = () => {
    setAlertData({
      message: "This feature is coming soon!!",
      type: "info",
    });
    //navigate("/auth/login");
  };

  return (
    <AppBar
      position="static"
      sx={{ background: "black", boxShadow: "none", ml: 0, mr: 0, padding: 0 }}
    >
      <Container sx={{ background: "black", boxShadow: "none", padding: 0 }}>
        <Toolbar
          sx={{
            padding: 0,
            margin: 0,
            flexDirection: { xs: "row", sm: "row" },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box
                sx={{
                  mt: 2,
                  ml: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  lineHeight: "1.1",
                  alignItems: "center",
                }}
              >
                <Logo />
                <Typography variant="h4" sx={{ ml: 1 }}>
                  AEG
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  mt: { xs: 1, sm: 2 },
                  flexDirection: { xs: "row", sm: "row" },
                  alignItems: { xs: "center", sm: "flex-start" },
                }}
              >
                <Button href="/" color="inherit">
                  Home
                </Button>
                <Button color="inherit" onClick={scrollToAbout}>
                  About
                </Button>
                <Button color="inherit" onClick={handleLoginClick}>
                  Buy
                </Button>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: { xs: "center", sm: "right" },
                  mt: { xs: 1, sm: 2 },
                }}
              >
                <Button
                  onClick={handleLoginClick}
                  variant="contained"
                  style={{
                    backgroundColor: "#F1B20F",
                    color: "black",
                    borderRadius: "25px",
                    padding: "10px 30px",
                  }}
                >
                  Login / Register
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
      <AlertComponent
        open={alertData.message !== "" ? true : false}
        onClose={() => setAlertData({ message: "", type: "error" })}
        message={alertData.message}
        type={alertData.type ? alertData.type : "error"}
      />
    </AppBar>
  );
};

export default Navbar;
