import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AEGDetails } from "../../types/AEGDetailsModel";

interface AEGDetailsState {
  result: AEGDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: AEGDetailsState = {
  result: null,
  loading: false,
  error: null,
};

const aegDetailsSlice = createSlice({
  name: "aegDetails",
  initialState,
  reducers: {
    aegDetailRequest(state) {
      state.loading = true;
      state.error = null;
    },
    aegDetailSuccess(state, action: PayloadAction<{ result: AEGDetails }>) {
      state.loading = false;
      state.result = action.payload.result;
    },
    aegDetailFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { aegDetailRequest, aegDetailSuccess, aegDetailFailure } =
  aegDetailsSlice.actions;

export default aegDetailsSlice.reducer;
