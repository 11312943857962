// ----------------------------------------------------------------------

export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: "#000000",
          backgroundColor: "#FFC800",
          boxShadow: theme.customShadows.z8,
          "&:hover": {
            backgroundColor: "#ECA400",
          },
        },
        containedPrimary: {
          color: "#000000",
          backgroundColor: "#FFC800",
          boxShadow: theme.customShadows.primary,
          "&:hover": {
            backgroundColor: "#ECA400",
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          color: "#FFFFFF",
          "&:hover": {
            color: "#ECA400",
          },
        },
      },
    },
  };
}
