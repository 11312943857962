import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrencyData } from "../../types/CurrencyListModel";

interface CurrencyState {
  result: CurrencyData | null;
  loading: boolean;
  error: string | null;
}

const initialState: CurrencyState = {
  result: null,
  loading: false,
  error: null,
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    currencyRequest(state) {
      state.loading = true;
      state.error = null;
    },
    currencySuccess(state, action: PayloadAction<{ result: CurrencyData }>) {
      state.loading = false;
      state.result = action.payload.result;
    },
    currencyFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { currencyRequest, currencySuccess, currencyFailure } =
  currencySlice.actions;

export default currencySlice.reducer;
