import { takeLatest, put, call } from "redux-saga/effects";

import axiosInstance from "../../api/config";
import {
  currencyRequest,
  currencyFailure,
  currencySuccess,
} from "../reducers/CurrencyListReducer";
import { CurrencyListResponse } from "../../types/CurrencyListModel";

function* requestCurrencySaga(): any {
  try {
    const response = yield call(axiosInstance.get, "/currency");
    if (response.status === 200) {
      const data: CurrencyListResponse = response.data;
      yield put(currencySuccess({ result: data.data }));
    } else {
      yield put(currencyFailure(response.message));
    }
  } catch (error: any) {
    yield put(
      currencyFailure(error.response ? error.response.data : error.message)
    );
  }
}

function* watchFetchCurrencySaga() {
  yield takeLatest(currencyRequest.type, requestCurrencySaga);
}

export default watchFetchCurrencySaga;
