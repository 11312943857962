import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfilePersonalDetails } from "../../types/ProfileModel";

interface ProfileState {
  result: ProfilePersonalDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  result: null,
  loading: false,
  error: null,
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    userProfileRequest(state) {
      state.loading = true;
      state.error = null;
    },
    userProfileSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.result = action.payload;
    },
    userProfileFailure(state, action: PayloadAction<string>) {
      state.result = null;
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { userProfileRequest, userProfileSuccess, userProfileFailure } =
  userProfileSlice.actions;

export default userProfileSlice.reducer;
