import { all } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import watchFetchAuthSaga from "./saga/authSaga";
import watchFetchDashboardSaga from "./saga/dashboardSaga";
import { watchFetchProfileSaga } from "./saga/profileSaga";
import watchFetchAEGDetailsSaga from "./saga/aegSaga";
import watchFetchCurrencySaga from "./saga/currencySaga";
import { watchFetchWalletSaga } from "./saga/walletSaga";
import { watchFetchTransactionSaga } from "./saga/transactionSaga";
import watchFetchBankSaga from "./saga/bankSaga";
import RootReducer from "./reducers/RootReducer";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

function* rootSaga() {
  yield all([
    watchFetchAuthSaga(),
    watchFetchDashboardSaga(),
    watchFetchProfileSaga(),
    watchFetchAEGDetailsSaga(),
    watchFetchCurrencySaga(),
    watchFetchWalletSaga(),
    watchFetchTransactionSaga(),
    watchFetchBankSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);
export type AppDispatch = typeof store.dispatch;
export default store;
